import React from 'react';
import * as R from 'ramda';
import {
  ArrayField,
  Datagrid,
  Error,
  Loading,
  TextField,
  useQuery,
} from 'react-admin';
import { Box, Typography } from '@material-ui/core';

import { QuestionField, HasCommentField } from './fields';
import { SurveyMetrics } from './components';

const CommentText = ({ record: survey }) => {
  const comment = R.pipe(
    R.propOr([], 'answers'),
    R.find(R.propEq('question', 'comments')),
    R.defaultTo({ answer: '' })
  )(survey);
  return <TextField record={comment} source="answer" />;
};

function SurveysTab({ record: student }) {
  const {
    data: cohort,
    loading,
    error,
  } = useQuery({
    type: 'getOne',
    resource: student.is_b2b ? 'cohorts_b2b' : 'cohorts',
    payload: { id: student.cohort },
  });

  if (loading)
    return (
      <Box m="16px">
        <Loading />
      </Box>
    );
  if (error)
    return (
      <Box m="16px">
        <Error error={error} errorInfo={error} />
      </Box>
    );

  const hasLegacyWeeklySurveysEnabled = R.pathSatisfies(
    R.equals(true),
    ['feature_flags', 'legacy_weekly_surveys_enabled'],
    cohort
  );

  return (
    <Box py="16px">
      <Box mx="16px">
        <Typography variant="h5" gutterBottom>
          Surveys Average
        </Typography>
      </Box>
      <SurveyMetrics student={student} />
      <Box mx="16px" mt="4em">
        <Typography variant="h5" gutterBottom>
          Answers
        </Typography>
      </Box>

      {!student.is_b2b && hasLegacyWeeklySurveysEnabled && (
        <ArrayField record={student} source="weekly_surveys">
          <Datagrid rowClick="expand" expand={<CommentText />}>
            <TextField label="Survey" source="survey" sortable={false} />
            <HasCommentField label="Comment" sortable={false} />
            <QuestionField
              label="Prework"
              questionId="prework"
              sortable={false}
            />
            <QuestionField
              label="Difficulty"
              questionId="challenge"
              sortable={false}
            />
            <QuestionField
              label="Instructor Clarity"
              questionId="instruction"
              sortable={false}
            />
            <QuestionField
              label="Material Clarity"
              questionId="material"
              sortable={false}
            />
            <QuestionField
              label="Instruction/Lab Alignment"
              questionId="lab_preparation"
              sortable={false}
            />
            <QuestionField
              label="Instructor Experience"
              questionId="instructional_staff"
              sortable={false}
            />
            <QuestionField
              label="IH Experience"
              questionId="ironhack"
              sortable={false}
            />
            <QuestionField
              label="Student Experience"
              questionId="overall_experience"
              sortable={false}
            />
            <QuestionField label="NPS" questionId="nps" sortable={false} />
          </Datagrid>
        </ArrayField>
      )}
      {!student.is_b2b && !hasLegacyWeeklySurveysEnabled && (
        <ArrayField record={student} source="weekly_surveys">
          <Datagrid rowClick="expand" expand={<CommentText />}>
            <TextField label="Survey" source="survey" sortable={false} />
            <HasCommentField label="Comment" sortable={false} />
            <QuestionField
              label="Feeling"
              questionId="feelings"
              sortable={false}
              hasLegacyWeeklySurveysEnabled={hasLegacyWeeklySurveysEnabled}
            />
            <QuestionField
              label="IH Experience"
              questionId="ironhack"
              sortable={false}
            />
            <QuestionField
              label="Material"
              questionId="material"
              sortable={false}
            />
            <QuestionField
              label="Learning"
              questionId="v2_learning"
              sortable={false}
            />
            <QuestionField
              label="Support"
              questionId="v2_support"
              sortable={false}
            />
            <QuestionField
              label="Tech support"
              questionId="v2_tech_support"
              sortable={false}
            />
            <QuestionField
              label="Tech support satisfaction"
              questionId="v2_tech_support_satisfaction"
              sortable={false}
            />
            <QuestionField
              label="AI Tools"
              questionId="v2_sp_ai"
              sortable={false}
            />
            <QuestionField
              label="Campus Experience"
              questionId="v2_campus_experience"
              sortable={false}
            />
            <QuestionField
              label="Job Readiness"
              questionId="v2_job_readiness"
              sortable={false}
            />
            <QuestionField
              label="Expectations"
              questionId="v2_expectations"
              sortable={false}
            />
            <QuestionField label="NPS" questionId="nps" sortable={false} />
            <QuestionField
              label="NPS promoter reason"
              questionId="v2_nps_reason_promoter"
              sortable={false}
            />
            <QuestionField
              label="NPS detractor reason"
              questionId="v2_nps_reason_detractor"
              sortable={false}
            />
          </Datagrid>
        </ArrayField>
      )}
      {student.is_b2b && (
        <ArrayField record={student} source="weekly_surveys">
          <Datagrid rowClick="expand" expand={<CommentText />}>
            <TextField label="Survey" source="survey" sortable={false} />
            <HasCommentField label="Comment" sortable={false} />
            <QuestionField
              label="Lead Teacher Experience"
              questionId="lead_teacher"
              sortable={false}
            />
            <QuestionField
              label="Teacher Assistant Experience"
              questionId="teacher_assistant"
              sortable={false}
            />
          </Datagrid>
        </ArrayField>
      )}
    </Box>
  );
}

export default SurveysTab;
